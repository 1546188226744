<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-6">
          © 2023 - Reservation Track System (RTS) -
          <pre style="display: inline">v{{ version }}</pre>
        </div>
        <div class="col-sm-6">
          <div class="text-sm-right d-none d-sm-block">
            Crafted with
            <Icon
              icon="jam:heart-f"
              color="red"
              :style="{ fontSize: '18px' }"
              :inline="true"
            />
            by MF Technology
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import appConfig from "@/app.config";
import { Icon } from "@iconify/vue2";
export default {
  name: "Footer",
  components: {
    Icon,
  },
  data() {
    return {
      version: appConfig.version,
    };
  },
};
</script>